.form {
    color: var(--gray);
    display: inline-block;
    max-width: 18.75rem;
  }
  
  .form label {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 1px;
    margin-top: 0.625rem;
  }
  
  .form input,
  .form textarea {
    background-color: transparent;
    border: 0.25px solid var(--gray);
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    width: 16.25rem;
  }
  
  .form input {
    height: 1.875rem;
  }
  
  .form textarea {
    height: 5rem;
  }
  
  .dataPolicy {
    display: inline-flex;
    justify-content: center;
    max-width: 16.25rem;
  }
  
  .dataPolicy input[type="checkbox"] {
    display: inline-block;
    margin-right: 0.6rem;
    max-width: 0.8rem;
  }
  
  .form button {
    background-color: var(--orange);
    border: none;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 600;
    height: 3.75rem;  
    margin-top: 1.25rem;
    transition: all 1s;
    width: 12.5rem;
  }
  
  .form button:focus {
    outline: none;
  }
  
  .form button:hover {
    background-color: var(--dark);
  }
  
  .message {
    margin-top: 1rem;
    color: var(--orange);
  }
  
  @media screen and (max-width: 767px) {
    .form {
      padding-left: 0;
    }
  }