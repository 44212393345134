.dataContainer {
  color: var(--dark);
  font-family: var(--raleway);
  padding: 3.125rem 12.5rem;
}

.dataSection {
  text-align: center;
}

.dataSection img {
  margin-bottom: 3.125rem;
  width: 200px;
}

.title {
  font-family: var(--montserat);
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.content {
  text-align: justify;
  font-size: 1rem;
}

.section {
  margin-top: 2.5rem;
}

.section h4 {
  color: var(--orange);
  font-family: var(--montserat);
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.section p, .section ul {
  font-weight: 100;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.section ul {
  padding-left: 1.25rem;
}

.section li {
  margin-bottom: 0.5rem;
}

.signature {
  font-weight: bold;
  text-align: right;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .dataContainer {
    padding: 3.125rem 1.25rem;
  }

  .title {
    font-size: 2rem;
  }
}