.propositionSection {
  margin-top: 9.375rem;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 2rem;
}

.link,
.storyItem a {
  color: var(--orange);
}

.prop {
  margin-top: 3.125rem;
}

.blackBox {
  background: var(--blue-gradient);
  color: var(--white);
  margin-left: 1.25rem;
  padding: 6.25rem 1.875rem;
}

.plsNote {
  font-size: 0.75rem;
  margin-top: 3.75rem;
}

.tryImage img {
  display: block;
  height: auto;
  margin: 6.25rem auto 1.875rem;
  max-width: 26.56rem;
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .propositionSection {
    margin-top: 8rem;
    padding: 0 3rem;
  }

  .story {
    flex-direction: column;
    gap: 3rem;
  }

  .blackBox {
    margin-left: 0;
    padding: 3rem;
  }

  .tryImage img {
    margin: 3rem auto;
    max-width: 20rem;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .propositionSection {
    margin-top: 18rem;
    padding: 0 1.5rem;
  }

  .story {
    display: block;
  }

  .plsNote {
    font-size: 0.625rem;
    margin-top: 1.25rem;
  }

  .tryImage img {
    margin: 3.125rem auto 10.625rem;
    max-width: 12.5rem;
  }
}