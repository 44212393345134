.servicesSection {
  margin: 12.5rem 0;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 2rem;
}

.maleImg {
  display: block;
  height: auto;
  max-width: 35.9375rem;
  padding: 0 3.125rem 0 0;
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .servicesSection {
    margin: 8rem 0;
    padding: 0 3rem;
  }

  .story {
    flex-direction: column;
    gap: 3rem;
  }

  .storyItem {
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;
  }

  .maleImg {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 2rem;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .servicesSection {
    margin: 6.25rem 0;
    padding: 0 1.5rem;
  }

  .story {
    display: block;
  }

  .maleImg {
    margin-bottom: 3.125rem;
    max-width: 20.3125rem;
    padding: 0;
  }
}