.whitepaper {
    display: flex;
    margin-top: 6.25rem;
}

.whitepaperContent {
    flex: 1;
    padding: 0 2.5rem 0 0;
}

.whitepaperImage {
    flex: 0 0 40%;
    position: relative;
    overflow: hidden;
}

.whitepaperImage img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.whitepaperContent h3 {
    font-family: var(--montserat);
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -1px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.whitepaperContent h5 {
    color: var(--light-blue);
    font-family: var(--montserat);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0 0 2rem 0;
    text-transform: uppercase;
}

.whitepaperContent h2 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
}

.whitepaperContent ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2rem;
}

.whitepaperContent li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;
}

.whitepaperContent li:before {
    content: "•";
    color: var(--orange);
    position: absolute;
    left: 0;
    top: 0;
}

.whitepaperContent p {
    margin-bottom: 1rem;
    text-transform: lowercase;
}

.orange {
    color: var(--orange);
}

.whitepaperContent button {
    background-color: var(--orange);
    border: none;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 600;
    height: 3.75rem;  
    margin-top: 1.25rem;
    transition: all 1s;
    width: 12.5rem;
}

.whitepaperContent button:hover {
    background-color: var(--dark);
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media screen and (max-width: 1024px) {
    .whitepaper {
        flex-direction: column;
    }

    .whitepaperImage {
        flex: 0 0 300px;
        order: -1;
    }

    .whitepaperContent {
        padding: 2rem 0;
    }
}

@media screen and (max-width: 767px) {
    .whitepaper {
        margin-top: 3.125rem;
    }

    .whitepaperImage {
        flex: 0 0 200px;
    }

    .whitepaperContent h3 {
        font-size: 1.5rem;
    }

    .whitepaperContent h5 {
        font-size: 0.9rem;
    }

    .whitepaperContent h2 {
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 1500px) {
    .whitepaper {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}   