.footer {
    color: var(--dark);
    margin-top: 12.5rem;
    text-align: center;
  }
  
  .socialProfiles a {
    padding-right: 0.625rem;
    color: var(--dark);
  }
  
  .footerContent {
    position: relative;
  }
  
  .line {
    position: absolute;
    bottom: 0;
    height: 0.5px;
  }
  
  .lineLeft {
    left: 0;
    width: 37.5rem;
    background: var(--footer-line-left);
  }
  
  .lineRight {
    right: 0;
    width: 37.5rem;
    background: var(--footer-line-right);
  }
  
  .rights p {
    margin-top: 6.25rem;
    font-size: 0.675rem;
    line-height: 0.75;
  }
  
  .business {
    font-size: 0.5rem;
  }
  
  @media screen and (max-width: 767px) {
    .lineLeft,
    .lineRight {
      width: 7.5rem;
    }
  }