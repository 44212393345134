.aboutSection {
    margin-top: 6.25rem;
  }

  .story {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .storyItem:nth-child(1),
  .storyItem:nth-child(2) {
    align-self: auto;
    display: block;
    flex-basis: calc(50% - 1rem); 
    flex-grow: 0;
    order: 0;
  }
  
  .storyImg {
    height: 37.5rem;
    width: 100%;
    object-fit: cover;
  }

  /* Tablet screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutSection {
    padding: 0 3rem;
  }

  .story {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .storyItem:nth-child(1),
  .storyItem:nth-child(2) {
    flex-basis: 100%;
    max-width: 35rem;
    margin: 0 auto;
  }

  .storyItem:nth-child(1) {
    margin-bottom: 2rem;
  }

  .storyImg {
    height: auto;
    max-height: 35rem;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}

/* Mobile screens */
@media screen and (max-width: 767px) {
  .aboutSection {
    padding: 0 1.5rem;
  }

  .story {
    display: block;
    margin-bottom: 3.125rem;
  }

  .storyItem:nth-child(1),
  .storyItem:nth-child(2) {
    width: 100%;
  }

  .storyImg {
    max-height: 25.625rem;
    margin-top: 3.125rem;
    width: 100%;
    padding: 0;
  }
}

/* Large screens */
@media screen and (min-width: 1500px) {
  .story {
    max-width: 90rem;
    margin: 0 auto;
  }

  .storyImg {
    height: auto;
    max-height: 45rem;
  }
}