Navbar.module.css
#navbar {
    width: 100%;
  }

  .navbarContainer {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 5rem;
    justify-content: center;
    position: fixed;
    transition: background-color 0.3s ease;
    z-index: 10;
    width: 100%;
  }

  .navbarContainer.scrolled {
    background-color: var(--dark);
  }

  .logo {
    flex-grow: 1;
    margin-left: 2.5rem;
    margin-top: 1.25rem;
  }

  .logo img {
    margin-left: 6.875rem;
    margin-top: 1.875rem;
    width: 12.5rem;
    transition: width 0.3s ease;
  }

  .menu {
    margin-right: 12.5rem;
  }

  .menu span {
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -0.05em;
    margin: 0 0.5rem;
    text-decoration: none;
  }

  .menu span:hover {
    color: var(--orange);
    text-decoration: none;
  }

  .jobsLink {
    color: var(--orange);
  }

  .active {
    color: var(--orange); 
  }

  @media screen and (max-width: 767px) {
    .logo img {
      margin: 0;
      width: 8.125rem;
    }

    .menu {
      margin: 1.25rem 3.75rem 1.25rem 0;
    }

    .menu span {
      display: block;
      font-size: 0.75rem;
      font-weight: 300;
      margin: 0 2px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .menu {
      margin-right: 3rem;
    }

    .logo img {
      margin-left: 0;
    }
  }