:root {
  --blue-gradient: radial-gradient(circle farthest-corner at bottom center, #042789 -95%, #010206 100%);
  --dark: #010206;
  --dark-blue: #042789;
  --gray: #8D8B8B;
  --light-blue: #A9CFE9;
  --footer-line-left: linear-gradient(to right, #555555, #ffffff);
  --footer-line-right: linear-gradient(to left, #555555, #ffffff);
  --montserat: 'Montserrat', sans-serif;
  --orange: #D8B725;
  --raleway: 'Raleway', sans-serif;
  --white: #fff;

    /* Breakpoints */
    --mobile: 767px;
    --tablet: 1024px;
    --laptop: 1366px;
    --desktop: 1920px;
}

body {
  color: var(--dark);
  font-family: var(--raleway);
  font-size: 16px;
}

a {
  color: var(--white);
  text-decoration: none;
}

b {
  font-weight: 400;
}

button {
  background-color: var(--orange);
  border: none;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 600;
  height: 3.75rem;  
  margin-top: 1.25rem;
  transition: all 1s;
  width: 12.5rem;
}

button:focus {
  outline: none;
}

button:hover {
  background-color: var(--dark);
}

h1 {
  animation: bounceIn;
  animation-duration: 2s;
  font-size: 3rem;
  font-weight: 600;
}

h3 {
  font-family: var(--montserat);
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: -1px;
  text-align: left;
  text-transform: uppercase;
}

h4 {
  color: var(--orange);
  font-family: var(--montserat);
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: uppercase;
}

h5 {
  color: var(--light-blue);
  font-family: var(--montserat);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

h6 {
  color: var(--light-blue);
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}

.headline {
  font-size: 1.375rem;
  text-transform: uppercase;
}

p {
  text-transform: lowercase;
} 

.orange {
  color: var(--orange);
}

/* Add any other global styles here */

AboutSection.module.css
.aboutSection {
  margin-top: 6.25rem;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
}

.storyItem:nth-child(1),
.storyItem:nth-child(2) {
  align-self: auto;
  display: block;
  flex-basis: auto;
  flex-grow: 0;
  order: 0;
}

.storyImg {
  height: 37.5rem;
  padding-left: 3.125rem;
}

@media screen and (max-width: 767px) {
  .story {
    display: block;
    margin-bottom: 3.125rem;
  }

  .storyImg {
    max-height: 25.625rem;
    margin-top: 3.125rem;
    max-width: 100%;
    padding-left: 0;
  }
}


