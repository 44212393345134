.portfolioSection {
  margin-top: 9.375rem;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 2rem;
}

.portfolioSection h3 {
  margin-bottom: 2rem;
}

.logoClient img {
  border: 1px solid #F2F2F2;
  border-radius: 0 3.125rem;
  height: 4rem;
  width: 7rem;
  margin: 0.5rem;
  padding: 0.625rem;
}

.femaleImg {
  display: block;
  height: auto;
  max-width: 40.625rem;
  padding: 0 0 0 3.125rem;
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .portfolioSection {
    margin-top: 8rem;
    padding: 0 3rem;
  }

  .story {
    flex-direction: column;
    gap: 3rem;
  }

  .storyItem {
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;
  }

  .femaleImg {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .portfolioSection {
    margin-top: 8rem;
    padding: 0 1.5rem;
  }

  .portfolioSection h3 {
    margin-bottom: 2rem;
  }

  .story {
    display: block;
  }

  .logoClient img {
    height: 4rem;
    width: 7rem;
    margin: 0.5rem;
  }

  .femaleImg {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}