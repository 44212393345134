.knowHow {
    font-family: var(--raleway);
    color: var(--dark);
}

.hero {
    height: 100vh;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.heroContent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 12.5rem; /* Align with the main content padding */
}

.heroTitle {
    color: var(--white);
    max-width: 60%;
}

.heroTitle h1 {
    animation: bounceIn 2s;
}

.headline {
    font-size: 1.375rem;
    text-transform: uppercase;
    margin-top: 1rem;
}

.main {
    padding: 6.25rem 12.5rem;
}

.orange {
    color: var(--orange);
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
    .heroContent {
        padding: 0 6.25rem; /* Align with the main content padding for this breakpoint */
    }

    .heroTitle {
        max-width: 70%;
    }

    .main {
        padding: 3.125rem 6.25rem;
    }
}

@media screen and (max-width: 767px) {
    .heroContent {
        padding: 0 4rem; /* Align with the main content padding for this breakpoint */
    }

    .heroTitle {
        max-width: 90%;
    }

    .headline {
        font-size: 1.125rem;
    }

    .main {
        padding: 3.125rem 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .heroContent,
    .main {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    .heroTitle {
        max-width: 50%;
    }
}

/* Animation for h1 */
@keyframes bounceIn {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}