.formContainer {
    max-width: 100%;
}

.rightForm {
    padding-left: 0;
}

.rightForm form {
    display: flex;
    flex-direction: column;
    max-width: 18.75rem;
    width: 100%;
}

.rightForm label {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 1px;
    margin-top: 0.625rem;
    color: var(--gray);
}

.rightForm input[type="text"],
.rightForm input[type="email"],
.rightForm textarea {
    background-color: transparent;
    border: 0.25px solid var(--gray);
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    width: 100%;
    height: 1.875rem;
}

.rightForm textarea {
    height: 5rem;
    resize: vertical;
}

.contactPolicy,
.dataPolicy {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    font-size: 0.75rem;
}

.contactPolicy input[type="checkbox"],
.dataPolicy input[type="checkbox"] {
    margin-right: 0.6rem;
    margin-top: 0.2rem;
}

.rightForm button {
    background-color: var(--orange);
    border: none;
    color: var(--white);
    font-size: 1.2rem;
    font-weight: 600;
    height: 3.75rem;
    margin-top: 1.25rem;
    transition: all 0.3s ease;
    width: 100%;
    cursor: pointer;
}

.rightForm button:hover {
    background-color: var(--dark);
}

.message {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .rightForm form {
        max-width: 100%;
    }
}