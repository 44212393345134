.contactSection {
  margin-top: 9.375rem;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 2rem;
}

.contactForm {
  padding: 6.25rem;
}

.phoneInfo {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}

.phoneInfo i {
  margin-right: 0.625rem;
}

.mobile {
  font-size: 1.75rem;
}

.blackBox {
  background: var(--blue-gradient);
  color: var(--white);
  padding: 6.25rem 1.875rem;
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contactSection {
    margin-top: 6.25rem;
    padding: 0 3rem;
  }

  .story {
    flex-direction: column;
    gap: 3rem;
  }

  .blackBox {
    margin: 0;
    padding: 3rem;
  }

  .contactForm {
    padding: 3rem 0;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .contactSection {
    margin-top: 6.25rem;
    padding: 0 1.5rem;
  }

  .mobile {
    font-size: 1.25rem;
  }

  .story {
    display: block;
  }

  .contactForm {
    margin-top: 3.125rem;
    padding: 0;
  }
}