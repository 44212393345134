.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popupContent {
    background-color: var(--white);
    margin: 0.625rem;
    padding: 5rem;
    border-radius: 5px;
    max-width: 50rem;
  }
  
  .close {
    color: var(--dark);
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: var(--orange);
    text-decoration: none;
    cursor: pointer;
  }
  
  .popupContent h3 {
    margin-bottom: 1rem;
  }
  
  .popupContent form {
    display: flex;
    flex-direction: column;
  }
  
  .popupContent input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid var(--gray);
  }
  
  .dataPolicy {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .dataPolicy input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .popupContent button {
    background-color: var(--orange);
    border: none;
    color: var(--white);
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .popupContent button:hover {
    background-color: var(--dark);
  }
  
  .message {
    margin-top: 1rem;
    color: var(--orange);
  }
  
  @media screen and (max-width: 767px) {
    .popupContent {
      padding: 2rem;
    }
  }