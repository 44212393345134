.label {
    font-size: 0.75rem;
    color: var(--gray);
  }
  
  .link {
    text-decoration: none;
  }
  
  .orange {
    color: var(--orange);
  }