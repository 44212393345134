/* PDFSection.module.css */

.pdf {
    padding: 3.125rem 0;
}

.mainTitle {
    text-align: left;
    margin-bottom: 2.5rem;
}

.pdfContent {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.25rem;
}

.pdfHalf {
    flex-basis: 50%;
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
}

.pdfHalf h4 {
    margin-bottom: 1.5rem;
}

.pdfColumns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.25rem;
}

.pdfColumn {
    flex-basis: 50%;
    padding: 0 1.25rem;
}

.pdfItem {
    margin-bottom: 2.5rem;
}

.pdfPicture {
    margin-top: auto; 
}

.pdfPicture img {
    display: block;
    width: 100%;
    height: auto;
}

.pdfSubscribe {
    background: var(--blue-gradient);
    color: var(--white);
    padding: 2.5rem;
    margin-top: auto; 
}

.pdfSubscribe h3 {
    margin-bottom: 1.5rem;
}

.pdfSubscribe input[type="email"] {
    width: 100%;
    padding: 0.625rem;
    margin-bottom: 1.25rem;
    background-color: transparent;
    border: 1px solid var(--orange);
    color: var(--white);
}

.dataPolicySubs {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.dataPolicySubs input[type="checkbox"] {
    margin-right: 0.625rem; 
}

.orange {
    color: var(--orange);
}

.styled {
    color: var(--gray);
    font-size: 0.75rem;
}

.subscribeH3 {
    color: white;
}

@media screen and (max-width: 1024px) {
    .pdfContent {
        flex-direction: column;
    }

    .pdfHalf {
        flex-basis: 100%;
    }
    
    .pdfPicture,
    .pdfSubscribe {
        margin-top: 2.5rem;
    }
}

@media screen and (max-width: 767px) {
    .pdf {
        padding: 1.5rem 0;
    }

    .pdfColumns {
        flex-direction: column;
    }

    .pdfColumn {
        flex-basis: 100%;
    }

    .pdfPicture,
    .pdfSubscribe {
        margin-top: 1.5rem;
    }

    .pdfSubscribe {
        padding: 1.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .pdf {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}