.talent {
    margin: 6.25rem 0;
    color: var(--white);
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.subSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.subItem {
    flex: 0 0 calc(65% - 1.25rem);
}

.subItem:last-child {
    flex: 0 0 calc(30% - 1.25rem);
}

.subItem h2 {
    color: var(--dark);
    margin-bottom: 3.125rem;
    font-size: 2rem; 
}

.subItem h5 {
    color: var(--light-blue); 
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.transparentBorder {
    background-color: transparent;
    border: none;
}

.blackBoxLeft {
    background: var(--blue-gradient);
    padding: 1.875rem;
}

.subSectionHalf {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.25rem;
}

.subSectionHalf > div {
    flex-basis: 50%;
    padding: 0 1.25rem;
}

.subItems {
    margin-bottom: 2.5rem;
}

.subItems h4 {
    min-height: 3.75rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    font-size: 1.25rem;
    color: var(--orange);
}

.styled {
    color: var(--gray);
    font-size: 0.75rem;
}

@media screen and (max-width: 1024px) {
    .subSection {
        flex-direction: column;
    }

    .subItem,
    .subItem:last-child {
        flex: 1 0 100%;
    }

    .subSectionHalf > div {
        flex-basis: 100%;
    }

    .subItem:last-child {
        margin-top: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .talent {
        padding: 1.5rem;
    }

    .subItem h2 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    .subItems h4 {
        min-height: auto;
        margin-bottom: 0.5rem;
    }
}

@media screen and (min-width: 1500px) {
    .content {
        max-width: 1400px;
    }
}