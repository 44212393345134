.container {
    align-items: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  
  .itemForm {
    display: block;
    flex-grow: 0;
    padding-top: 6.25rem;
  }
  
  .items {
    background: var(--blue-gradient);
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    margin-left: 3.125rem;
    padding: 6.25rem 1.25rem;
  }
  
  .itemCard {
    margin-top: 0;
  }
  
  .itemCard h4 {
    display: block;
    min-height: 6.25rem;
  }
  
  .itemCard h6 {
    display: block;
    min-height: 5rem;
  }
  
  .itemCard:nth-child(1),
  .itemCard:nth-child(2),
  .itemCard:nth-child(3) {
    display: inline-block;
    min-width: 12.5rem;
    padding: 0 0.625rem;
  }
  
  .pWhite {
    color: var(--white);
    font-size: 0.875rem;
    margin-top: 3.125rem;
  }
  
  @media screen and (max-width: 767px) {
    .container {
      display: block;
    }
  
    .itemForm {
      padding-top: 0;
    }
  
    .items {
      display: block;
      flex-grow: 0;
      margin-left: 0;
      margin-top: 3.75rem;
      padding: 1.25rem;
    }
  
    .itemCard {
      margin-top: 3.125rem;
    }
  
    .itemCard:nth-child(1),
    .itemCard:nth-child(2),
    .itemCard:nth-child(3) {
      display: block;
      min-width: 6.25rem;
      padding: 0;
    }
  
    .itemCard h4,
    .itemCard h6 {
      min-height: auto;
    }
  }