/* Jobs.module.css */

.hero {
    height: 100vh;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.heroContent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 12.5rem; /* Align with the logo in navbar */
}

.heroTitle {
    color: var(--white);
    max-width: 600px;
}

.heroTitle h1 {
    margin-bottom: 1.25rem;
}

.main {
    padding: 6.25rem 12.5rem; /* Align with the hero content */
    max-width: 1200px;
    margin: 0 auto;
}

.main h3 {
    margin-bottom: 2.5rem;
}

.waitingList {
    margin-top: 3.75rem;
}

.pdfSubscribe {
    background: var(--blue-gradient);
    color: var(--white);
    padding: 2.5rem;
}

.subscribeH3 {
    margin-bottom: 1.5rem;
}

.styled {
    color: var(--gray);
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
}

.pdfSubscribe form {
    display: flex;
    flex-direction: column;
    max-width: 25rem;
}

.pdfSubscribe label {
    color: var(--white);
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
}

.waiting {
    background-color: transparent;
    border: 1px solid var(--orange);
    color: var(--white);
    font-size: 1rem;
    height: 2.5rem;
    margin-bottom: 1.25rem;
    padding: 0.625rem;
    width: 100%;
}

.dataPolicySubs {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
}

.dataPolicySubs input[type="checkbox"] {
    margin-right: 0.625rem;
}

.pdfSubscribe button {
    margin-top: 1.25rem;
    cursor: pointer;
}

.message {
    margin-top: 1.25rem;
    color: var(--orange);
}

@media screen and (max-width: 1024px) {
    .heroContent,
    .main {
        padding-left: 6.25rem;
        padding-right: 6.25rem;
    }
}

@media screen and (max-width: 768px) {
    .heroContent,
    .main {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
    }
}
