.recommendationSection {
  margin: 9.375rem 0;
}

.story {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 2rem;
}

.maleImg {
  display: block;
  height: auto;
  max-width: 35.9375rem;
  padding: 0 3.125rem 0 0;
}

.recommend {
  margin: 9.375rem 0 0 0;
}
  
  .slider {
    height: 20rem;
    margin: 1.25rem auto 0;
    overflow: visible;
    position: relative;
    width: 25rem;
  }
  
  .mask {
    height: 28.125rem;
    overflow: hidden;
  }
  
  .slider ul {
    margin: 0;
    padding: 0;
    position: relative;
  }
  
  .slider li {
    height: 20rem;
    list-style: none;
    position: absolute;
    top: -20.3125rem; 
    width: 25rem;
  }
  
  .quote {
    font-size: 1rem;
    font-style: italic;
  }
  
  .source {
    font-size: 1rem;
    text-align: right;
  }
  
  .source a {
    color: var(--orange);
    text-decoration: none;
  }
  
  .source a:hover {
    color: var(--dark-blue);
  }
  
  .anim1 { animation: cycle 20s linear infinite; }
  .anim2 { animation: cycle2 20s linear infinite; }
  .anim3 { animation: cycle3 20s linear infinite; }
  .anim4 { animation: cycle4 20s linear infinite; }
  .anim5 { animation: cycle5 20s linear infinite; }
  
  .slider:hover li {
    animation-play-state: paused;
  }
  
  @keyframes cycle {
    0% { top: 0px; }
    4% { top: 0px; }
    16% { top: 0px; opacity: 1; z-index: 0; }
    20% { top: 325px; opacity: 0; z-index: 0; }
    21% { top: -325px; opacity: 0; z-index: -1; }
    50% { top: -325px; opacity: 0; z-index: -1; }
    92% { top: -325px; opacity: 0; z-index: 0; }
    96% { top: -325px; opacity: 0; }
    100% { top: 0px; opacity: 1; }
  }
  
  @keyframes cycle2 {
    0% { top: -325px; opacity: 0; }
    16% { top: -325px; opacity: 0; }
    20% { top: 0px; opacity: 1; }
    24% { top: 0px; opacity: 1; }
    36% { top: 0px; opacity: 1; z-index: 0; }
    40% { top: 325px; opacity: 0; z-index: 0; }
    41% { top: -325px; opacity: 0; z-index: -1; }
    100% { top: -325px; opacity: 0; z-index: -1; }
  }
  
  @keyframes cycle3 {
    0% { top: -325px; opacity: 0; }
    36% { top: -325px; opacity: 0; }
    40% { top: 0px; opacity: 1; }
    44% { top: 0px; opacity: 1; }
    56% { top: 0px; opacity: 1; z-index: 0; }
    60% { top: 325px; opacity: 0; z-index: 0; }
    61% { top: -325px; opacity: 0; z-index: -1; }
    100% { top: -325px; opacity: 0; z-index: -1; }
  }
  
  @keyframes cycle4 {
    0% { top: -325px; opacity: 0; }
    56% { top: -325px; opacity: 0; }
    60% { top: 0px; opacity: 1; }
    64% { top: 0px; opacity: 1; }
    76% { top: 0px; opacity: 1; z-index: 0; }
    80% { top: 325px; opacity: 0; z-index: 0; }
    81% { top: -325px; opacity: 0; z-index: -1; }
    100% { top: -325px; opacity: 0; z-index: -1; }
  }
  
  @keyframes cycle5 {
    0% { top: -325px; opacity: 0; }
    76% { top: -325px; opacity: 0; }
    80% { top: 0px; opacity: 1; }
    84% { top: 0px; opacity: 1; }
    96% { top: 0px; opacity: 1; z-index: 0; }
    100% { top: 325px; opacity: 0; z-index: 0; }
  }
  
  .orange {
    color: var(--orange);
  }
  
 /* Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .recommendationSection {
    margin: 8rem 0;
    padding: 0 3rem;
  }

  .story {
    flex-direction: column;
    gap: 3rem;
  }

  .storyItem {
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;
  }

  .maleImg {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 2rem;
  }

  .recommend {
    margin: 4rem 0 0 0;
  }

  .slider {
    width: 100%;
    max-width: 25rem;
  }
}

/* Mobile */
@media screen and (max-width: 767px) {
  .recommendationSection {
    margin: 6.25rem 0;
    padding: 0 1.5rem;
  }

  .story {
    display: block;
  }

  .maleImg {
    margin-bottom: 3.125rem;
    max-width: 20.3125rem;
    padding: 0;
  }

  .recommend {
    margin: 3.125rem 0 0 0;
  }

  .slider li {
    width: 18.75rem;
  }

  .quote {
    font-size: 0.8rem;
  }
}