/* Team image section */
.teamImg {
  display: block;
  margin-top: 12.5rem;
  text-align: center;
}

.teamImg img {
  display: inline-block;
  max-width: 56.25rem;
}

/* Main team section */
.teamSection {
  display: block;
  text-align: center;
  width: auto;
}

.radiantBckg {
  background: var(--blue-gradient);
  display: inline-block;
  height: 18.75rem;
  margin-left: -13.125rem;
  position: relative;
  width: 100vw;
}

/* Team cards container */
.theTeam {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(-49%);
  position: relative;
  z-index: 2;
  margin-bottom: -8rem;
}

/* Individual member cards */
.memberCard {
  display: block;
  min-height: 21.875rem;
  padding: 0 1.25rem;
  width: 13.75rem;
}

/* Member card top section */
.memberCardTop {
  color: var(--white);
  justify-content: center;
}

.memberCardTop h4 {
  display: block;
  min-height: 3.125rem;
}

.memberImg {
  border-radius: 50%;
  padding: 1em 0;
  width: 160px;
  height: 210px;
  object-fit: cover;
}

/* Social icons */
.social {
  display: flex;
  justify-content: center;
  margin: 3.125rem 0;
}

.icon {
  background-color: var(--white);
  border-radius: 50%;
  color: var(--dark);
  height: 1.6875rem;
  justify-content: center;
  margin: 2px;
  overflow: hidden;
  position: relative;
  width: 1.875rem;
}

.icon i {
  font-size: 1rem;
  line-height: 1.875rem;
  transition: all 0.2s;
}

.icon:hover i {
  color: var(--white);
  transform: scale(1.3);
}

.icon:before {
  background-color: var(--dark);
  content: "";
  height: 120%;
  left: -110%;
  position: absolute;
  top: 90%;
  transform: rotate(45deg);
  width: 120%;
}

.icon:hover:before {
  animation: iconAnimation 0.7s 1 forwards;
}

@keyframes iconAnimation {
  0% { top: 90%; left: -110%; }
  50% { top: -30%; left: 15%; }
  100% { top: -10%; left: -10%; }
}

/* Member card bottom section */
.memberCardBottom {
  color: var(--gray);
  margin-top: 5rem;
  text-align: left;
}

.memberCardBottom p {
  font-size: 0.875rem;
}

.memberCardBottom a {
  color: var(--orange);
}

/* Tablet and smaller desktop */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .teamSection {
    height: auto;
    padding: 0 3rem;
  }

  .teamImg {
    margin-top: 6.25rem;
    width: 100%;
  }

  .teamImg img {
    width: 100%;
    max-width: 48rem;
  }

  .radiantBckg {
    display: none; /* Hide radiant background on iPad */
  }

  .theTeam {
    display: flex;
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
    transform: none;
    margin: 3rem 0;
  }

  .memberCard {
    width: 100%;
    max-width: 35rem; /* Limit maximum width for readability */
    margin-bottom: 4rem;
    padding: 0;
  }

  .memberCard:last-child {
    margin-bottom: 0;
  }

  .memberCard:nth-child(even) {
    background-color: var(--dark);
    padding: 2rem 0;
  }

  .memberCard:nth-child(even) .memberCardBottom p {
    color: var(--white);
  }

  .memberCardTop {
    padding: 2rem 0;
  }

  .memberCardBottom {
    padding: 0 2rem;
  }

  .social {
    margin: 1rem 0;
  }
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .teamSection {
    height: auto;
    margin: 0; /* Reset margins */
    padding-bottom: 5rem; /* Add padding instead of margin */
    position: relative;
  }

  .teamImg {
    margin-top: 6.25rem;
    margin-bottom: 3rem; /* Add space between image and cards */
  }

  .teamImg img {
    max-width: 21.875rem;
  }

  .radiantBckg {
    display: none; /* Hide the radiant background on mobile */
  }

  .theTeam {
    display: block;
    transform: none; /* Remove transform */
    margin-top: 0; /* Reset margin */
    padding-top: 2rem; /* Add padding for spacing */
  }

  .memberCard {
    margin: 0 auto 3rem; /* Center cards and add bottom margin */
    padding: 0;
    width: 90%; /* Make cards slightly narrower than container */
    max-width: 20rem; /* Limit maximum width */
  }

  .memberCard:last-child {
    margin-bottom: 0; /* Remove margin from last card */
  }

  .memberCard:nth-child(even) {
    background-color: var(--dark);
    padding: 1.875rem 0;
  }

  .memberCard:nth-child(even) .memberCardBottom p {
    color: var(--white);
  }

  .memberCardTop {
    padding: 2rem 0;
  }

  .memberCardTop h4 {
    min-height: auto;
  }

  .memberCardBottom h5,
  .memberCardBottom p {
    margin: 1.25rem 0.625rem;
  }

  .social {
    margin: 0.625rem 0 2.5rem;
  }
}
/* Large screens */
@media screen and (min-width: 1500px) {
  .teamImg img {
    max-width: 62rem;
  }

  .theTeam {
    margin-top: -18rem;
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -10rem; 
  }
}