.hero {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100vh;
    width: auto;
  }
  
  .heroContent {
    display: flex;
  }
  
  .heroTitle {
    color: var(--white);
    margin: 12.5rem 0 0 12.5rem;
  }
  
  @media screen and (max-width: 767px) {
    .hero {
      height: 58.25rem;
    }
  
    .heroTitle {
      margin: 12.5rem 0 0 3.125rem;
    }
  }